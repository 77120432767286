import React, { Fragment, useState } from 'react'
import PropTypes from "prop-types"
import { Popover, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import examplePng from '../images/logo@2x.png'
import { Link } from "gatsby"

const navigation = [
  { name: 'Product', href: '/' },
  { name: 'Features', href: '/' },
  { name: 'Marketplace', href: '/' },
  { name: 'Company', href: '/' },
]

const Header = ({ siteTitle }) => {
  const [isMenuOpen, setMenuOpen] = useState(false)

  return (
    <header
    >
      <Popover>
        <div>
          <nav className="flex flex-wrap items-center justify-between px-6 py-3 fixed w-[100%] bg-white">
            <div className="flex items-center flex-shrink-0 mr-6 text-white">
              <span className="h-10 font-semibold tracking-tight">
                <img src={examplePng} alt="" className='h-[100%] m-0' />
              </span>
            </div>
            <div className="block lg:hidden">
              <button
                onClick={() => {
                  setMenuOpen((isMenuOpen) => !isMenuOpen)
                }}
                className="flex items-center px-3 py-2 bg-green-500 text-white border border-teal-500 rounded hover:text-white hover:border-white"
              >
                <svg
                  className="w-3 h-3 fill-current"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Menu</title>
                  <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                </svg>
              </button>
            </div>
            <div
              className={`w-full ${isMenuOpen ? "block" : "hidden"
                } lg:block flex-grow lg:flex lg:items-center lg:w-auto`}
            >
              <div className="text-sm lg:flex-grow">
                {/* <a
                  href="#responsive-header"
                  className="block mt-4 mr-4 text-teal-200 lg:inline-block lg:mt-0 hover:text-white"
                >
                  Docs
                </a>
                <a
                  href="#responsive-header"
                  className="block mt-4 mr-4 text-teal-200 lg:inline-block lg:mt-0 hover:text-white"
                >
                  Examples
                </a>
                <a
                  href="#responsive-header"
                  className="block mt-4 text-teal-200 lg:inline-block lg:mt-0 hover:text-white"
                >
                  Blog
                </a> */}
              </div>
              <div>
                <Link to="/login" style={{textDecoration: 'unset'}}>
                  <button
                    className="flex items-center px-3 py-2  text-[#2E2F33] border border-【#EEEEEE】 rounded mr-5"
                  >
                    登 入
                  </button>
                </Link>
              </div>
              <div>
                <Link to="/register" style={{textDecoration: 'unset'}}>
                  <button
                    className="flex px-3 py-2  text-white hover:text-slate-200 bg-[#14CC71] border border-【#EEEEEE】 rounded "
                  >
                    免费注册
                  </button>
                </Link>
              </div>
            </div>
          </nav>
        </div>

        {/* <Transition
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute inset-x-0 top-0 z-10 origin-top-right transform p-2 transition md:hidden"
          >
            <div className="overflow-hidden rounded-lg bg-white shadow-md ring-1 ring-black ring-opacity-5">
              <div className="flex items-center justify-between px-5 pt-4">
                <div>
                  <img
                    className="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/workflow-mark.svg?color=indigo&shade=600"
                    alt=""
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close main menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="space-y-1 px-2 pt-2 pb-3">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="block rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              <a
                href="#"
                className="block w-full bg-gray-50 px-5 py-3 text-center font-medium text-indigo-600 hover:bg-gray-100"
              >
                Log in
              </a>
            </div>
          </Popover.Panel>
        </Transition> */}
      </Popover>

    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
