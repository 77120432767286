import * as React from "react"
import { Divider } from "antd"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Divider plain></Divider>
    <div className=" text-center pt-10">
      <h1 className=" text-4xl">Page not found!</h1>
      <p className=" text-sm max-w-[100%]">Oops! The page you are looking for has been removed or relocated.</p>
      <a href="/">Go Back</a>
    </div>
  </Layout>
)

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage
